import { pushElementInteraction } from '@frontend/analytics';
import { externalRoutes, getLandingUrlPerEnv, INTERACTIONS } from '@frontend/shared';
import {
    BankIdIcon,
    Box,
    Button,
    Carousel,
    FontAwesomeIcon,
    IconsRegular,
    Image,
    Stack,
    Typography,
} from '@lendoab/aphrodite';
import React, { ReactElement, useState } from 'react';

import { Divider } from '../Divider';
import BankIDView from './BankIDView';
import styles from './Login.module.scss';

type ViewProps = {
    setShowBankId: (value: boolean) => void;
};

const slides = [
    {
        title: '“Det var smidigt och lätt att klicka sig fram till ett lån. Och jag fick mycket bättre ränta än jag haft tidigare.”',
        description: '– Privatlånekund',
        imageUrl: `${externalRoutes.cdn}/img/carousels/inboxLogin/privateLeif.png`,
    },
    {
        title: '“Kunniga medarbetare, snabb service och kontakt vid uppdateringar i ärendet som gällde en flytt/nyteckning av bolån.”',
        description: '– Bolånekund',
        imageUrl: `${externalRoutes.cdn}/img/carousels/inboxLogin/mortgageLeif.png`,
    },
    {
        title: '“Enklare, smidigare, bättre information och mer hjälpsamt kan det nog inte bli!”',
        description: '– Företagslånekund',
        imageUrl: `${externalRoutes.cdn}/img/carousels/inboxLogin/businessLeif.png`,
    },
];

export default function Login(): ReactElement {
    const [showBankId, setShowBankId] = useState(false);

    return showBankId ? <BankIDView setShowBankId={setShowBankId} /> : <LogInView setShowBankId={setShowBankId} />;
}

function LogInView({ setShowBankId }: ViewProps): ReactElement {
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            paddingTop={['small', 'small', '7xl']}
            paddingBottom={['6xl', '8xl']}
            style={{ width: '100vw', height: '100vh', overflow: 'scroll' }}
        >
            <Box display="flex" flexDirection="column" style={{ height: '100%', margin: '0 auto' }}>
                <Typography.Link
                    display="flex"
                    alignItems="center"
                    gap="small"
                    onClick={() =>
                        pushElementInteraction({
                            id: 'tillbaka',
                            group: 'login',
                            type: INTERACTIONS.CLICK,
                        })
                    }
                    href={getLandingUrlPerEnv()}
                    marginTop={['medium', 'medium', 'none']}
                    marginLeft={['medium', 'medium', 'none']}
                >
                    <FontAwesomeIcon color="blue-40" icon={IconsRegular.faArrowLeft} /> Lendo.se
                </Typography.Link>
                <Stack
                    alignItems="center"
                    position="relative"
                    paddingX={['medium', 'medium', 'none']}
                    className={styles.container}
                    style={{ maxWidth: '496px' }}
                    marginX="none"
                    paddingBottom={['none', '5xl']}
                >
                    <Box textAlign={['center', 'center', 'left']}>
                        <Image
                            style={{ width: '90px', marginBottom: '24px' }}
                            src={`${externalRoutes.cdn}/img/logos/lendo_full_green.svg`}
                            alt="lendo logo"
                        />
                        <Typography.Title level={1} marginBottom="medium" color="dark" weight="bold">
                            Logga in på Mina sidor
                        </Typography.Title>

                        <Typography.Body color="dark">
                            Logga in med BankID för att hantera dina ansökningar eller utforska våra lån och
                            försäkringar.
                        </Typography.Body>
                        <Button
                            marginTop="xl"
                            type="button"
                            onClick={() => setShowBankId(true)}
                            fluid
                            primary
                            size="medium"
                        >
                            Logga in med BankID
                            <BankIdIcon />
                        </Button>
                        <Box display="flex" alignItems="center" marginY="xl">
                            <Divider />
                            <Typography.Body marginX="xl">Eller</Typography.Body>
                            <Divider />
                        </Box>
                        <Box display="flex" flexDirection={['column', 'column', 'row']} alignItems="center">
                            <Typography.Body paddingLeft={['none', 'none', '2xl']} component="label">
                                Har du ingen aktiv ansökan?
                            </Typography.Body>
                            <Button
                                color="blue"
                                display="flex"
                                alignItems="center"
                                component="a"
                                size="small"
                                href={getLandingUrlPerEnv()}
                                borderless
                                onClick={() => {
                                    pushElementInteraction({
                                        id: 'Jämför lån nu',
                                        group: 'login',
                                        type: INTERACTIONS.CLICK,
                                    });
                                }}
                                marginTop={['small', 'small', 'none']}
                                marginLeft={['none', 'none', 'small']}
                                paddingRight={['none', 'none', '5xl']}
                            >
                                Jämför lån nu
                                <FontAwesomeIcon icon={IconsRegular.faArrowRight} />
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Box>
            <Box
                display={['none', 'none', 'block']}
                className={styles.sliderContainer}
                marginRight={['none', 'none', '6xl']}
            >
                <Carousel slides={slides} interval={8000} />
            </Box>
        </Box>
    );
}
