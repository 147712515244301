import { pushElementInteraction } from '@frontend/analytics';
import BankId from '@frontend/bank-id';
import { externalRoutes, INTERACTIONS } from '@frontend/shared';
import { Box, Card, Image } from '@lendoab/aphrodite';
import * as Sentry from '@sentry/react';
import { Analytics } from 'APP/Analytics';
import { AuthData, useAuth } from 'APP/Authentication';
import { isNotProduction } from 'APP/helpers/envHelpers';
import React, { ReactElement } from 'react';

import styles from './Login.module.scss';

const DATALAYER_CONTEXT = 'login';

export default function BankIDView({ setShowBankId }: { setShowBankId: (value: boolean) => void }): ReactElement {
    const { bankIdAuth } = useAuth();

    return (
        <Box
            className={styles.container}
            paddingTop={['small', 'small', '7xl']}
            paddingBottom={['6xl', '8xl']}
            style={{ minHeight: '100vh' }}
            backgroundColor="blue-99"
            display={['block', 'flex', 'block']}
            flexDirection="column"
            justifyContent="center"
        >
            {/* DESKTOP & Tablet. */}
            <Box display={['none', 'flex', 'flex']} justifyContent="center" marginTop={['5xl', 'none', 'none']}>
                <Image
                    style={{ width: '90px' }}
                    src={`${externalRoutes.cdn}/img/logos/lendo_full_green.svg`}
                    alt="lendo logo"
                />
            </Box>
            <Card
                alignSelf="center"
                padding="none"
                className={styles.cardContainer}
                marginTop={['xl', '2xl', '2xl']}
                position="relative"
                boxShadow="none"
                style={{ maxWidth: '544px' }}
            >
                {/* MOBILE */}
                <Box
                    display={['block', 'none', 'none']}
                    style={{ position: 'absolute', top: '24px', right: '32px', width: '67px' }}
                >
                    <Image src={`${externalRoutes.cdn}/img/logos/lendo_full_green.svg`} alt="lendo logo" />
                </Box>
                <BankId
                    action="auth"
                    endpoint="auth/bankid-auth"
                    devMode={isNotProduction()}
                    onBack={(): void => {
                        pushElementInteraction({
                            id: 'tillbaka',
                            group: 'bankid',
                            type: INTERACTIONS.CLICK,
                        });
                        setShowBankId(false);
                    }}
                    onVerified={(data): void => {
                        bankIdAuth(data as AuthData);
                    }}
                    onError={(error): void => {
                        Sentry.captureException(error);
                    }}
                    onEvent={(data): void => {
                        const { event, payload } = data;

                        switch (event) {
                            case 'VERIFIED':
                                Analytics.pushBankIdProgress('bankIdSuccess', {
                                    context: DATALAYER_CONTEXT,
                                    method: payload.trackingMethod,
                                });
                                break;
                            case 'ERROR_OCCURRED':
                                Analytics.pushBankIdProgress('bankIdFailure', {
                                    context: DATALAYER_CONTEXT,
                                    method: payload.trackingMethod,
                                    reason: payload.error,
                                });
                                break;
                            case 'INITIATED':
                                Analytics.pushBankIdProgress('bankIdInit', {
                                    context: DATALAYER_CONTEXT,
                                    method: payload.trackingMethod,
                                });
                                break;
                        }
                    }}
                />
            </Card>
        </Box>
    );
}
